import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,

} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SiteOwner from './SiteOwner';
import Cookies from 'js-cookie';

const pages = [
  { name: 'Home', hash: '#home' },
  { name: 'Games', hash: '#games' },
  { name: 'Rewards', hash: '#rewards' },
  { name: 'Values', hash: '#values' },
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const hasSponsorInfo = Boolean(Cookies.get('business-SponsorID'));
  const sponsorBarHeight = { xs: '120px', sm: '80px' }; // 50% taller on mobile

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const getJoinUrl = () => {
    const sponsorId = Cookies.get('business-SponsorID');
    const sponsorName = Cookies.get('business-SponsorName');
    
    if (sponsorId && sponsorName) {
      return `https://www.cs4000.me/PlayFI/enroll/signup_form.asp?pkg=1&sponsorid=${sponsorId}&sponsorname=${encodeURIComponent(sponsorName)}`;
    }
    return 'https://lockit.playafi.com'; // Default URL if no sponsor info
  };

  const handleNavClick = (hash, url) => {
    if (url) {
      window.location.href = url;  // Navigate to external URL
    } else {
      window.location.hash = hash;  // Set hash for internal navigation
    }
    handleCloseNavMenu();
  };

  return (
    <Box>
      {hasSponsorInfo && (
        <AppBar 
          position="fixed" 
          sx={{ 
            bgcolor: 'black',
            height: sponsorBarHeight,  // Dynamic height
            zIndex: 1200,
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
              <SiteOwner business={true} />
            </Toolbar>
          </Container>
        </AppBar>
      )}
      
      <AppBar 
        position="fixed" 
        sx={{ 
          background: 'linear-gradient(to right, #1a237e, #000051)',
          top: hasSponsorInfo ? sponsorBarHeight : 0,  // Dynamic positioning
          zIndex: 1100,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Logo/Brand - desktop */}
            <Box
              component="img"
              sx={{
                mr: 4,
                display: { xs: 'none', md: 'flex' },
                height: '50px',
              }}
              alt="PlayaFi"
              src="/PlayaFi-Logo-white-small.png"
            />

            {/* Mobile menu and logo container */}
            <Box sx={{ 
              flexGrow: 1, 
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center'
            }}>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {/* Mobile logo */}
              <Box
                component="img"
                sx={{
                  ml: 2.5,
                  display: { xs: 'flex', md: 'none' },
                  height: '40px',
                }}
                alt="PlayaFi"
                src="/PlayaFi-Logo-white-small.png"
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {pages.map((page) => (
                  <MenuItem 
                    key={page.name} 
                    onClick={() => handleNavClick(page.hash)}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Desktop menu - main navigation */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handleNavClick(page.hash, page.url)}
                  sx={{ my: 2, mx: 0.5, color: 'white' }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            {/* Auth buttons - visible at all screen sizes */}
            <Box sx={{ display: 'flex' }}>  {/* Removed display breakpoint */}
              <Button
                onClick={() => handleNavClick(null, 'https://www.cs4000.me/PlayFi/member/login_amb.asp')}
                sx={{ 
                  my: 2, 
                  mx: 1,
                  color: 'white'
                }}
              >
                Login
              </Button>
              <Button
                onClick={() => handleNavClick(null, getJoinUrl())}
                sx={{ 
                  my: 2, 
                  mx: 1, 
                  backgroundColor: '#ffffff',
                  color: '#000051',
                  borderRadius: '6px',
                  padding: '3px 20px',
                  fontSize: '0.95rem',
                  fontWeight: 900,
                  '&:hover': {
                    backgroundColor: '#ffffff',
                  }
                }}
              >
                JOIN
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header; 