import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

const Join = () => {
  useEffect(() => {
    const sponsorId = Cookies.get('business-SponsorID');
    const sponsorName = Cookies.get('business-SponsorName');
    
    if (sponsorId && sponsorName) {
      window.top.location.replace(`https://www.cs4000.me/PlayFI/enroll/signup_form.asp?pkg=1&sponsorid=${sponsorId}&sponsorname=${encodeURIComponent(sponsorName)}`);
    } else {
      window.top.location.replace('https://lockit.playafi.com');
    }
  }, []);

  // Optional loading state while redirect happens
  return (
    <div style={{ 
      height: '100vh', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      background: '#000'
    }}>
      Redirecting...
    </div>
  );
};

export default Join; 